const menuStyle = {
  root: {
    height: 100,
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    maxWidth: 2000,

    justifyContent: 'center',
    zIndex: 1000,
    // '@media (max-width: 820px)': {
    //   display: 'none',
    // },
  },
  isVisible: {},
  wrapper: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  logo: {


    paddingLeft: 10,
    fontSize: 25,
    color: '#1a1a1a',
    textAlign: 'center',
    transition: 'all .4s ease',
    fontFamily: '\'Poppins\', sans-serif',
    fontWeight: 500,
    textTransform: 'none',

  },
}

export default menuStyle
