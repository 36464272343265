import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import mainMenuStyle from './mainMenuStyle.jsx'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Logo from '../../Logo/Logo'
import LogoDark from '../../Logo/LogoDark'


const MainMenuDato = props => {
  const data = useStaticQuery(graphql`
      query {
          germanMenu: datoCmsMenu(locale:{eq:"de"}) {
              mainMenu {
                  label
                  link {
                      __typename
                      ... on DatoCmsWetsuitFinder {
                          locale
                          url
                      }
                      ... on DatoCmsSizeChart {
                          locale
                          url
                      }
                      ... on DatoCmsGuide {
                          locale
                          url
                      }
                      ... on DatoCmsSizeFinder {
                          locale
                          url
                      }
                  }
              }
          }
          englishMenu: datoCmsMenu(locale:{eq:"en"}) {

              mainMenu {

                  label
                  link {
                      __typename
                      ... on DatoCmsWetsuitFinder {
                          locale
                          url
                      }
                      ... on DatoCmsSizeChart {
                          locale
                          url
                      }
                      ... on DatoCmsGuide {
                          locale
                          url
                      }
                      ... on DatoCmsSizeFinder {
                          locale
                          url
                      }
                  }
              }
          }
      }

  `)
  const { classes, light, actLang,showLogo } = props
  const liClasses = classNames({
    [classes.menuItem]: true,
    [classes.menuItemLight]: light,
  })

  return (

    <nav className={classes.root}>
      {actLang === 'de' ?
        <div style={{ height: 100 }}>
          <ul className={classes.mainMenuWrapper}>
            <li className={liClasses}>
              <Link to={actLang === 'de' ? '/de/' : '/'}>
                Home
              </Link>
            </li>
            {data.germanMenu.mainMenu.map(item => (
              <li className={liClasses}>
                <Link to={'/de/' + item.link.url}>
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        :
        <div style={{ height: 100 }}>
          <ul className={classes.mainMenuWrapper}>
            <li className={liClasses}>
              <Link to={actLang === 'de' ? '/de/' : '/'}>
                Home
              </Link>
            </li>
            {data.englishMenu.mainMenu.map(item => (
              <li className={liClasses}>
                <Link to={item.link.url}>
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      }

    </nav>


  )

}

MainMenuDato.defaultProps = {
  logoAsH1: false,
  showLogo: true,
  light: false,
  actLang: 'en',
}

MainMenuDato.propTypes = {
  logoAsH1: PropTypes.bool,
  showLogo: PropTypes.bool,
  light: PropTypes.bool,
  actLang: PropTypes.string,
}

export default withStyles(mainMenuStyle)(MainMenuDato)
