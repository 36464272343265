const headerStyle = theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    padding: 0,
    zIndex: 10,
    width: '100%',

  }, hideOnResponsive: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  siteHeaderContent: {

    width: '100%',


  },
  foofoo: {
    position: 'fixed',
    height: 200,
    width: 200,
    zIndex: 99,
    backgroundColor: 'black',
  },
  current: {
    backgroundColor: 'khaki',
  },
  other: {
    backgroundColor: 'red',
  },
  logo: {
    margin: 0,
    padding: 0,
    position: 'absolute',
    zIndex: 12,
    top: 100,
    width: '100%',
    fontSize: 36,
    paddingTop: 40,
    lineHeight: '24px',
    color: '#fff',
    textAlign: 'center',
    transition: 'all .4s ease',
    fontFamily: '\'Poppins\', sans-serif',
    fontWeight: 500,
    textTransform: 'none',
    letterSpacing: -2,
  },
})

export default headerStyle
