import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import logoStyle from './logoStyle'

const Logo = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "assets/img/logo_white.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 300, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      } 
    }
  `)
  return (
    <Img
      fluid={data.file.childImageSharp.fluid}
      alt={"Perfect Wetsuit Logo"}
      backgroundColor={'transparent'}
      style={{ maxHeight: 350,  height:"100%"}}
    />
  )
}
export default withStyles(logoStyle)(Logo)
