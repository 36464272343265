import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { maxContainerWidth, section } from 'assets/jss/material-kit-react.jsx'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const styles = {
  section: {
    ...section,
    paddingTop: 120,
    paddingBottom: 120,
  },
  wrapper: {
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',

    ...maxContainerWidth,
  },
  flexRow:{
    flexDirection: 'row',
    alignItems: 'flex-start',
    '@media (max-width: 1020px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  }
}



function Section({ ...props }) {
  const { classes, children } = props
  const allClasses = classNames({
    [classes.wrapper]: true,
    [classes.flexRow]: props.flexDirRow,
  })
  return (
    <section
      className={classes.section}
      style={
        props.smallLeftRightPadding
          ? {
              backgroundColor: props.backgroundColor,
              paddingTop: props.paddingTop,
              paddingBottom: props.paddingBottom,
              paddingLeft: 5,
              paddingRight: 5,
            }
          : {
              backgroundColor: props.backgroundColor,
              paddingTop: props.paddingTop,
              paddingBottom: props.paddingBottom,
            }
      }
    >
      <div className={allClasses} style={props.innerStyles}>
        {children}
      </div>
    </section>
  )
}

Section.defaultProps = {
  backgroundColor: '#fff',
  paddingTop: 120,
  paddingBottom: 120,
  innerStyles: {},
  smallLeftRightPadding: false,
  flexDirRow: false,
}

Section.propTypes = {
  backgroundColor: PropTypes.string,
  paddingTop: PropTypes.number,
  paddingBottom: PropTypes.number,
  innerStyles: PropTypes.object,
  smallLeftRightPadding: PropTypes.bool,
  flexDirRow: PropTypes.bool,
}

export default withStyles(styles)(Section)
