import React from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'

import menuStyle from './menuStyle'

import BurgerMenu from '../BurgerMenu/BurgerMenu'
import { Link } from 'gatsby'
import LogoDark from '../../Logo/LogoDark'
import MainMenuDato from '../MainMenuDato/MainMenu'
import Logo from '../../Logo/Logo'


const Menu = props => {
  const { classes, actLang, localURLS, light, compact } = props

  return (
    <div className={classes.root}>
      {compact ? <Link to={actLang === 'de' ? '/de/' : '/'} style={{ width: 'auto' }}>
        <div className={classes.wrapper}>
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            width: 100,
            height: '100%',
            paddingLeft: 40,
          }}>
            <div style={{ width: 50 }}>
              {light ? <Logo/> : <LogoDark/>}
            </div>
          </div>
          {/*<h1 className={classes.logo}>*/}
          {/*  Perfect Wetsuit*/}
          {/*</h1>*/}
        </div>
      </Link> : null }
      {/*  <div className={classes.wrapper}>*/}
      {/*  <div style={{*/}
      {/*    display: 'flex',*/}
      {/*    justifyContent: 'center',*/}
      {/*    alignItems: 'center',*/}
      {/*    width: 100,*/}
      {/*    height: '100%',*/}
      {/*    paddingLeft: 40,*/}
      {/*  }}>*/}
      {/*    <div style={{ width: 50 }}>*/}
      {/*     */}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*  /!*<h1 className={classes.logo}>*!/*/}
      {/*  /!*  Perfect Wetsuit*!/*/}
      {/*  /!*</h1>*!/*/}
      {/*</div>*/}


      <MainMenuDato
        actLang={actLang}
        logoAsH1={false}
        light={light}

      />

      <BurgerMenu
        localURLS={localURLS}
        actLang={actLang}
        light={light}/>

    </div>

  )

}

Menu.defaultProps = {
  light: false,
  compact: false,
}
Menu.propTypes = {
  light: PropTypes.bool,
  compact: PropTypes.bool,
}

export default withStyles(menuStyle)(Menu)
