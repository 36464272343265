/*eslint-disable*/
import React from 'react'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// nodejs library that concatenates classes
import classNames from 'classnames'
import { withStyles } from '@material-ui/core'
import footerStyle from './footerStyle.jsx'
// @material-ui/icons
import { graphql, Link, navigate, useStaticQuery } from 'gatsby'
import Section from '../Section/Section'
import CookieConsent from 'react-cookie-consent'

const Footer = (props) => {
  const data = useStaticQuery(graphql`
      query {
          germanMenu: datoCmsMenu(locale:{eq:"de"}) {
              mainMenu {
                  label
                  link {
                      __typename
                      ... on DatoCmsWetsuitFinder {
                          locale
                          url
                      }
                      ... on DatoCmsSizeChart {
                          locale
                          url
                      }
                      ... on DatoCmsGuide {
                          locale
                          url
                      }
                      ... on DatoCmsSizeFinder {
                          locale
                          url
                      }
                      ... on DatoCmsPrivacyStatement {
                          locale
                          url
                      }
                      ... on DatoCmsTermsOfService {
                          locale
                          url
                      }
                  }
              }
              burgerMenuBottomLine {
                  label
                  link {
                      __typename
                      ... on DatoCmsWetsuitFinder {
                          locale
                          url
                      }
                      ... on DatoCmsSizeChart {
                          locale
                          url
                      }
                      ... on DatoCmsGuide {
                          locale
                          url
                      }
                      ... on DatoCmsSizeFinder {
                          locale
                          url
                      }
                      ... on DatoCmsPrivacyStatement {
                          locale
                          url
                      }
                      ... on DatoCmsTermsOfService {
                          locale
                          url
                      }
                  }
              }
          }
          englishMenu: datoCmsMenu(locale:{eq:"en"}) {

              mainMenu {

                  label
                  link {
                      __typename
                      ... on DatoCmsWetsuitFinder {
                          locale
                          url
                      }
                      ... on DatoCmsSizeChart {
                          locale
                          url
                      }
                      ... on DatoCmsGuide {
                          locale
                          url
                      }
                      ... on DatoCmsSizeFinder {
                          locale
                          url
                      }
                      ... on DatoCmsPrivacyStatement {
                          locale
                          url
                      }
                      ... on DatoCmsTermsOfService {
                          locale
                          url
                      }
                  }
              }
              burgerMenuBottomLine {
                  label
                  link {
                      __typename
                      ... on DatoCmsWetsuitFinder {
                          locale
                          url
                      }
                      ... on DatoCmsSizeChart {
                          locale
                          url
                      }
                      ... on DatoCmsGuide {
                          locale
                          url
                      }
                      ... on DatoCmsSizeFinder {
                          locale
                          url
                      }
                      ... on DatoCmsPrivacyStatement {
                          locale
                          url
                      }
                      ... on DatoCmsTermsOfService {
                          locale
                          url
                      }
                  }
              }
          }
      }

  `)
  const { classes, actLang } = props
  const footerClasses = classNames({
    [classes.footer]: true,
    // [classes.footerWhiteFont]: whiteFont,
  })

  return (
    <footer className={classes.root}>
      <Section backgroundColor={'#e3eff0'} paddingBottom={0}
               innerStyles={{
                 maxWidth: 1210,

               }}
      >
        <div className={classes.footerMenusWrapper}>
          <nav role="navigation"
               className={classNames(classes.navWrapper)}>
            <h2 className={classes.poppinsHeader}>
              {actLang === 'de' ? 'Hauptmenü' : 'Main Menu'}</h2>

            <ul className={classes.menuWrapper}>
              <li>
                <Link className={classes.P} to={actLang === 'de' ? '/de/' : '/'}>
                  Home
                </Link>
              </li>
              {actLang === 'de' ?
                data.germanMenu.mainMenu.map(item => (
                  <li>
                    <Link className={classes.P} to={'/de/' + item.link.url}>
                      {item.label}
                    </Link>
                  </li>
                ))
                : null}
              {actLang === 'en' ?
                data.englishMenu.mainMenu.map(item => (
                  <li>
                    <Link className={classes.P} to={item.link.url}>
                      {item.label}
                    </Link>
                  </li>
                ))
                : null}
            </ul>
          </nav>

          <nav role="navigation"
               className={classNames(classes.centerOnBreak)}>
            <h2 className={classes.poppinsHeader}>
              {actLang === 'de' ? 'Wichtige Links' : 'Important Links'}
            </h2>

            <ul className={classes.menuWrapper}>
              {actLang === 'de' ?
                data.germanMenu.burgerMenuBottomLine.map((item, key) => (

                  <li>
                    <Link className={classes.P} to={'/de/' + item.link.url}>
                      {item.label}
                    </Link>
                  </li>


                ))
                : null}
              {actLang === 'en' ?
                data.englishMenu.burgerMenuBottomLine.map((item, key) => (
                  <li>
                    <Link className={classes.P} to={item.link.url}>
                      {item.label}
                    </Link>
                  </li>
                ))
                : null}


            </ul>
          </nav>
        </div>
        <div className={classes.creditWrapper}>
          <ul className={classes.credit} role="contentinfo">
            <li className={classes.copyright}>Copyright © 2019 Perfect Wetsuit</li>
          </ul>


        </div>

      </Section>
      <CookieConsent
        enableDeclineButton
        flipButtons
        hideOnAccept={true}
        location={'bottom'}
        style={{ width: 350, border: '1px solid #1a1a1a', margin: 15, color: '#1a1a1a', backgroundColor: 'white' }}
        buttonText={'Ok'}
        declineButtonText={actLang === 'de' ? "Mehr Infos": "More Infos"}

        onDecline={() => navigate(actLang === 'de' ? '/de/datenschutzerklaerung' : "/privacy-statement")}

        declineButtonStyle={{
          width: '90%',
          marginTop: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: '"Cormorant Garamond", sans-serif',
          color: '#1a1a1a',

          lineHeight: 1.45,
          fontSize: 18,
          textDecoration: 'underline',

          backgroundColor: 'transparent',


        }}
        buttonStyle={{

          width: '90%',
          marginTop: 5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: '"Cormorant Garamond", sans-serif',
          color: '#1a1a1a',
          padding: 10,
          lineHeight: 1.45,
          fontSize: 22,
          paddingLeft: 40,
          paddingRight: 40,

          backgroundColor: 'transparent',
          border: '1px solid #1a1a1a',
          transition: 'all 0.5s ease',

        }}

      >
        <span className={classes.cfont}>
         {actLang === 'de' ? ' Nach EU-Richtlinie weisen wir hiermit auf die Nutzung von Cookies hin' :
           'We are using Cookies to provide you the best possible experience '}
        </span>


      </CookieConsent>

    </footer>
  )
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  whiteFont: PropTypes.bool,
}

export default withStyles(footerStyle)(Footer)
