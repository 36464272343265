import React, { useState } from 'react'
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Link, useStaticQuery ,graphql} from 'gatsby'
import burgerMenuStyle from './burgerMenuStyle'

import { Dialog } from '@material-ui/core'
import classNames from 'classnames'


function BurgerMenu(props) {
  const data = useStaticQuery(graphql`
      query {
          germanMenu: datoCmsMenu(locale:{eq:"de"}) {
              mainMenu {
                  label
                  link {
                      __typename
                      ... on DatoCmsWetsuitFinder {
                          locale
                          url
                      }
                      ... on DatoCmsSizeChart {
                          locale
                          url
                      }
                      ... on DatoCmsGuide {
                          locale
                          url
                      }
                      ... on DatoCmsSizeFinder {
                          locale
                          url
                      }
                      ... on DatoCmsPrivacyStatement {
                          locale
                          url
                      }
                      ... on DatoCmsTermsOfService {
                          locale
                          url
                      }
                  }
              }
              burgerMenuBottomLine {
                  label
                  link {
                      __typename
                      ... on DatoCmsWetsuitFinder {
                          locale
                          url
                      }
                      ... on DatoCmsSizeChart {
                          locale
                          url
                      }
                      ... on DatoCmsGuide {
                          locale
                          url
                      }
                      ... on DatoCmsSizeFinder {
                          locale
                          url
                      }
                      ... on DatoCmsPrivacyStatement {
                          locale
                          url
                      }
                      ... on DatoCmsTermsOfService {
                          locale
                          url
                      }
                  }
              }
          }
          englishMenu: datoCmsMenu(locale:{eq:"en"}) {

              mainMenu {

                  label
                  link {
                      __typename
                      ... on DatoCmsWetsuitFinder {
                          locale
                          url
                      }
                      ... on DatoCmsSizeChart {
                          locale
                          url
                      }
                      ... on DatoCmsGuide {
                          locale
                          url
                      }
                      ... on DatoCmsSizeFinder {
                          locale
                          url
                      }
                      ... on DatoCmsPrivacyStatement {
                          locale
                          url
                      }
                      ... on DatoCmsTermsOfService {
                          locale
                          url
                      }
                  }
              }
              burgerMenuBottomLine {
                  label
                  link {
                      __typename
                      ... on DatoCmsWetsuitFinder {
                          locale
                          url
                      }
                      ... on DatoCmsSizeChart {
                          locale
                          url
                      }
                      ... on DatoCmsGuide {
                          locale
                          url
                      }
                      ... on DatoCmsSizeFinder {
                          locale
                          url
                      }
                      ... on DatoCmsPrivacyStatement {
                          locale
                          url
                      }
                      ... on DatoCmsTermsOfService {
                          locale
                          url
                      }
                  }
              }
          }
      }

  `)
  const [state, setState] = useState({ open: false })

  const handleOpen = () => {
    setState({ open: true })
  }
  const handleClose = () => {
    setState({ open: false })
  }

  const { classes, light, actLang } = props

  // const pClasses = classNames[
  //   light ? classes.light : classes.dark
  // langActive
  //   ]

  return (
    <div className={classes.root}>
      <div style={{ flexDirection: 'row', display: 'flex' }}>

        <div style={{

          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          marginTop: -5,

          marginRight: 20,

        }}>
          {props.localURLS.map((item, key) => {
              return (
                <div style={{ display: 'flex', marginRight: 10, flexDirection: 'row' }}>
                  <Link
                    to={item.locale === 'de' ? '/de/' + item.value : '/' + item.value}>
                    <p
                      className={classNames(
                        classes.langSwitchP,
                        actLang === item.locale ? classes.langActive : null,
                        light ? classes.light : null,
                      )}
                    >{item.locale.toUpperCase()}</p>
                  </Link>
                  {key !== props.localURLS.length - 1 ?
                    <div><p className={classNames(light ? classes.light : null,
                    )} style={{ margin: 0, marginLeft: 10 }}>/</p></div> : null}
                </div>

              )
            },
          )}
        </div>
        <button onClick={handleOpen}
                style={{
                  fontSize: '100%',
                  fontFamily: 'inherit',
                  border: 0,
                  padding: 0,
                  backgroundColor: 'transparent',
                  cursor: 'pointer',
                }}
                className={classes.wrapper}
        >
          <div className={classes.bar} style={light ? { backgroundColor: '#fff' } : { backgroundColor: '#1a1a1a' }}/>
          <div className={classes.bar} style={light ? { backgroundColor: '#fff' } : { backgroundColor: '#1a1a1a' }}/>
          <div className={classes.bar} style={light ? { backgroundColor: '#fff' } : { backgroundColor: '#1a1a1a' }}/>

        </button>
      </div>

      <Dialog
        open={state.open}
        onClose={handleClose}
        className={classes.modal}
        fullScreen
        fullWidth
        transitionDuration={{ enter: 300, exit: 500 }}
      >
        <div className={classes.modalWrapper}>

          <div className={classes.xWrapper}>
            <div style={{
              flexDirection: 'row',
              display: 'flex',
              marginTop: 5,
              marginRight: 30,
            }}>
              <button onClick={handleClose}
                      style={{ fontSize: '100%', fontFamily: 'inherit', border: 0, cursor: 'pointer' }}
                      className={classes.closeX}>
                <div className={classes.closeBar} style={{ transform: 'rotate(45deg)' }}/>
                <div className={classes.closeBar} style={{ transform: 'rotate(-45deg)' }}/>
              </button>
            </div>

          </div>

          <nav>
            <ul className={classes.mainMenuWrapper}>
              <li className={classes.menuItem}>
                <Link to={actLang === 'de' ? '/de/' : '/'} onClick={handleClose}>
                  Home
                </Link>
              </li>
              {actLang === 'de' ?
                data.germanMenu.mainMenu.map(item => (
                  <li className={classes.menuItem}>
                    <Link to={'/de/' + item.link.url} onClick={handleClose}>
                      {item.label}
                    </Link>
                  </li>
                ))
                : null}
              {actLang === 'en' ?
                data.englishMenu.mainMenu.map(item => (
                  <li className={classes.menuItem}>
                    <Link to={item.link.url} onClick={handleClose}>
                      {item.label}
                    </Link>
                  </li>
                ))
                : null}
              <ul className={classes.bottomMenuWrapper}>
                {actLang === 'de' ?
                  data.germanMenu.burgerMenuBottomLine.map((item, key) => (
                    <>
                      <li className={classes.bottomItem}>
                        <Link to={'/de/' + item.link.url} onClick={handleClose}>
                          {item.label}
                        </Link>
                      </li>
                      {key !== data.germanMenu.burgerMenuBottomLine.length - 1 ?
                        <div className={classes.bottomMenudivider}>・</div> : null}
                    </>
                  ))
                  : null}
                {actLang === 'en' ?
                  data.englishMenu.burgerMenuBottomLine.map((item, key) => (
                    <>
                      <li className={classes.bottomItem}>
                        <Link to={item.link.url} onClick={handleClose}>
                          {item.label}
                        </Link>
                      </li>

                      {key !== data.englishMenu.burgerMenuBottomLine.length - 1 ?
                        <div className={classes.bottomMenudivider}>・</div> : null}
                    </>
                  ))
                  : null}
              </ul>
            </ul>
          </nav>
        </div>
      </Dialog>
    </div>


  )

}

BurgerMenu.defaultProps = {
  light: false,
  localURLS: [
    { 'locale': 'de', 'value': '' },
    { 'locale': 'en', 'value': '' },
  ],
}

BurgerMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  light: PropTypes.bool,
  localURLS: PropTypes.object,
}

export default withStyles(burgerMenuStyle)(BurgerMenu)

