import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

let siteURL = 'https://perfectwetsuit.com'

const SEO = props => {

  const { seoMetaTags, actLang, localURLS } = props

  let getTitle = 'Perfect Wetsuit'

  if (seoMetaTags) {
    seoMetaTags.map(item => {
      if (item.tagName === 'title') {
        getTitle = item.content
      }
      return null
    })
  }

  if (props.home) {
    getTitle = 'Home'
  }

  console.log('seoMetaTags!!', seoMetaTags)
  console.log('getTitle!!', getTitle)

  let alternateSiteURL = 'https://perfectwetsuit.com'

  if (actLang === 'en') {
    alternateSiteURL = 'https://perfectwetsuit.com/de'
  }

  let alternateURL = siteURL
  let actLangSet = actLang === 'de' ? 'en' : 'de'
  if (localURLS) {
    localURLS.map(item => {
      if (item.locale !== actLang) {
        alternateURL = alternateSiteURL + '/' + item.value
      }
    })
  }

  let siteURLGet = siteURL
  let base = siteURL
  if (actLang === 'de') {
    base = siteURL + '/de'
  }

  if (localURLS) {
    localURLS.map(item => {
      if (item.locale === actLang) {
        siteURLGet = base + '/' + item.value
      }
    })
  }

  return (
    <>
      <Helmet title={getTitle} titleTemplate={'%s · Perfect Wetsuit'}>

        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
        <meta property="og:site_name" content="Perfect Wetsuit"/>
        <meta property="og:url" content={siteURLGet}/>


        <link rel="alternate" hrefLang={actLangSet} href={alternateURL}/>

        {!props.article ? <meta property={'og:type'} content={'Website'}/> : null}

        {seoMetaTags ? seoMetaTags.map(item => {
          // item.tagName === 'meta' ? item.attributes.hasOwnProperty('name') ?
          //   <meta name={item.attributes.name} content={item.attributes.content}/> : null : null
          if (item.tagName === 'meta') {
            if (item.attributes.hasOwnProperty('name')) {
              return <meta name={item.attributes.name} content={item.attributes.content}/>
            }
            if (item.attributes.hasOwnProperty('name')) {
              return <meta name={item.attributes.name} content={item.attributes.content}/>
            }
            if (item.attributes.hasOwnProperty('property')) {

              // if (props.home) {
              //   if (item.attributes.property === 'og:image') {
              //     return <meta property={'image'} content={item.attributes.content}/>
              //   }
              //
              // }

              if (item.attributes.content === 'article') {
                if (props.article) {
                  return <meta property={item.attributes.property} content={item.attributes.content}/>
                } else {
                  return null
                }
              }
              return <meta property={item.attributes.property} content={item.attributes.content}/>
            }
            return null
          } else {
            return null
          }
        }) : null}
      </Helmet>
    </>
  )
}
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  home: PropTypes.bool,

  article: PropTypes.bool,
  seoMetaTags: PropTypes.object,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
  seoMetaTags: null,
  home: false,
  localURLS: null,
}
const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl: url
                defaultImage: image

            }
        }
    }
`