import React from 'react'

import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import fixedMenuStyle from './fixedMenuStyle'
import { Slide } from '@material-ui/core'

import Menu from '../Menu/Menu'


class FixedMenu extends React.Component {

  constructor(props) {
    super(props)

    this.state = {

      visible: false,
    }

  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {


    const currentScrollPos = window.pageYOffset
    const visible = currentScrollPos > 150

    this.setState({
      prevScrollpos: currentScrollPos,
      visible,
    })
  }


  render() {
    const { classes,actLang,localURLS } = this.props
    const { visible } = this.state
    return (
      <Slide timeout={{ enter: 1000, exit: 1000 }} direction="down" in={visible} mountOnEnter unmountOnExit
             className={classNames(classes.root)}>
        <div style={{ width: '100%', display: 'flex'}}>
          <Menu
          localURLS={localURLS}
          actLang={actLang}
          light={false}
          compact
        />
        </div>

      </Slide>
    )
  }
}


// PageHeader.propTypes = {
//   classes: PropTypes.object.isRequired,
//   className: PropTypes.string,
//   filter: PropTypes.bool,
//   children: PropTypes.node,
//   style: PropTypes.string,
//   image: PropTypes.string,
// }

export default withStyles(fixedMenuStyle)(FixedMenu)
