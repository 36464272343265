import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Header from './Header'


const styles = {
  onDesktop: {

    '@media (max-width: 768px)': {
      display: 'none',
    },
  },
  onMobile: {
    width: '100%',
    display: 'none',
    '@media (max-width: 768px)': {
      display: 'inherit',
    },
  },
}

const HeaderProvider = props => {
  const { classes } = props
  return (

    <div style={{ width: '100%' }}>
      <div className={classes.onDesktop}>
        {/*<SearchButtonFullWidth */}
        {/*                       searchButtonText={labels.searchButtonText}*/}
        {/*/>*/}
        <Header
          localURLS={props.localURLS}
          actLang={props.actLang}
          SEOtitle={props.SEOtitle}
          SEOdescription={props.SEOdescription}
          SEOpathname={props.SEOpathname}
          absolute={props.absolute}
          logoAsH1={props.logoAsH1}
          light={props.light}
          compact={props.compact}
          seoMetaTags={props.seoMetaTags}
        />
      </div>
      <div className={classes.onMobile}>
        <Header
          localURLS={props.localURLS}
          actLang={props.actLang}
          SEOtitle={props.SEOtitle}
          SEOdescription={props.SEOdescription}
          SEOpathname={props.SEOpathname}
          absolute={props.absolute}
          logoAsH1={props.logoAsH1}
          light={props.light}
          seoMetaTags={props.seoMetaTags}
          compact
        />
      </div>
    </div>
  )


}

HeaderProvider.defaultProps = {
  absolute: false,
  light: false,
  logoAsH1: false,
  compact: false,

}

HeaderProvider.propTypes = {
  classes: PropTypes.object.isRequired,
  light: PropTypes.bool,
  logoAsH1: PropTypes.bool,
  compact: PropTypes.bool,
}

export default withStyles(styles)(HeaderProvider)
