import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'

import headerStyle from './headerStyle.jsx'
import FixedMenu from './FixedMenu/FixedMenu'
import SEO from '../SEO'
import Menu from './Menu/Menu'
import { Link } from 'gatsby'
import Logo from '../Logo/Logo'
import LogoDark from '../Logo/LogoDark'


const Header = props => {

  const {
    classes,
    light,
    absolute,
    SEOtitle,
    SEOdescription,
    SEOimage,
    SEOpathname,
    SEOarticle,
    compact,
    actLang,
  } = props

  return (

    <header className={classes.root} style={absolute ? {

      position: 'absolute',

    } : { backgroundColor: 'white' }}>

      {/*<SEO*/}
      {/*  title={SEOtitle}*/}
      {/*  description={SEOdescription}*/}
      {/*  image={SEOimage}*/}
      {/*  pathname={SEOpathname}*/}
      {/*  article={SEOarticle}*/}
      {/*  seoMetaTags={props.seoMetaTags}*/}
      {/*/>*/}


      <div className={classes.siteHeaderContent}>
        {/*<MainMenu*/}
        {/*  actLang={props.actLang}*/}
        {/*  location={props.location}*/}
        {/*  logoAsH1={logoAsH1} light={!!light}/>*/}

        <FixedMenu
          localURLS={props.localURLS}
          actLang={actLang}
          compact

        />

        <div style={{ width: '100%', display: 'flex',justifyContent: 'center' }}>
          <Menu
            localURLS={props.localURLS}
            actLang={actLang}
            light={light}
            compact={compact}
          />
        </div>

        {
          !compact ? <div style={{

            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 0,
            paddingBottom: 80,
            '@media (max-width: 768px)': {
              paddingTop: 0,
            },

          }}>
            <Link to={actLang === 'de' ? '/de/' : '/'}>
              <div style={{ width: 70, height: 70, marginTop: 30 }}>
                {light ? <Logo/> : <LogoDark/>}
              </div>
            </Link>
          </div> : null
        }
        {/*<MainMenuDato*/}
        {/*  actLang={props.actLang}*/}
        {/*  logoAsH1={logoAsH1}*/}
        {/*  light={!!light}*/}

        {/*/>*/}
        {/*<BurgerMenu*/}
        {/*  localURLS={props.localURLS}*/}
        {/*  actLang={props.actLang}*/}
        {/*  light={!!light}/>*/}
      </div>


    </header>

  )
}

Header.defaultProps = {
  absolute: false,
  light: false,
  logoAsH1: false,
  compact: false,

}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  light: PropTypes.bool,
  logoAsH1: PropTypes.bool,
  compact: PropTypes.bool,
}

export default withStyles(headerStyle)(Header)
