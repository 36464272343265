import { H1, P, poppinsHeader } from 'assets/jss/material-kit-react.jsx'

const bannerImgBgStyle = {
  root: {
    minHeight: 600,
    maxHeight: 600,
    // backgroundColor: 'rgba(255,239,239,0.9)',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageStyles: {
    height: '100%',
  },
  wrapper: {
    width: '100%',
    minHeight: 600,
    maxHeight: 600,
    display: 'flex',
    maxWidth: 1210,
    zIndex: 6,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    // backgroundColor: 'green',
    '@media (max-width: 1060px)': {
      flexDirection: 'column',
      maxWidth: 790,
    },
  },
  H1: {
    ...H1,
    margin: 0,
    padding: 0,
  },
  P: {
    ...P,
    lineHeight: 1.45,
    color: 'white',
  },
  poppinsHeader: {
    ...poppinsHeader,
    margin: 0, padding: 0,
    marginBottom: 20,
  },
  subTitle: { padding: 0, margin: 0 },
  textWrapper: {
    // backgroundColor: 'khaki',
    width: '60%',
    '@media (max-width: 1060px)': {
      width: '100%',
    },
  },
  buttonMainWrapper: {
    height: '100%',
    width: '40%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // backgroundColor: 'red',
    '@media (max-width: 1060px)': {

      width: '100%',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    // backgroundColor: 'khaki',
  },
  buttonWrapper: {
    height: 175,
    width: 175,
    backgroundColor: 'black',
    borderRadius: 99,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 1s ease',
    '&:hover': {
      transform: 'scale(1.2)',
    },
    '@media (max-width: 1060px)': {
      height: 120,
      width: 120,
    },

  },
  sectionTitleTop: {
    margin: 0,
    padding: 0,
    fontFamily: '\'Poppins\', sans-serif',
    fontSize: 12,
    textTransform: 'uppercase',

    letterSpacing: '3px',
  },
  sectionTitleBottom: {
    margin: 0,
    padding: 0,
    fontFamily: '"Cormorant Garamond", "Helvetica", "Arial", sans-serif',
    letterSpacing: '-1px',
    lineHeight: 1.06,
    marginBottom: 25,
    paddingBottom: 3,
  },
}

export default bannerImgBgStyle
