import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import pageHeaderStyle from './pageHeaderStyle'
import Img from 'gatsby-image'
import Section from '../Section/Section'

class PageHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}

  }

  componentDidMount() {

  }

  componentWillUnmount() {

  }

  render() {
    const {
      classes,
      image,
      title,
      subtitle,
      date,
      alt,
    } = this.props

    const imageWrapperClasses = classNames({
      [classes.imageWrapper]: true,
      [classes.bigWrapper]: this.props.big,
      [classes.smallWrapper]: !this.props.big,
    })
    const imageClasses = classNames({
      [classes.big]: this.props.big,
      [classes.small]: !this.props.big,
    })


    return (
      <div className={classes.root}>

        {image ? <div className={imageWrapperClasses}>
          <Img
            className={imageClasses}
            fluid={image}
            objectFit="contain"
            objectPosition="50% 50%"
            backgroundColor={'#f2f2f2'}
            alt={alt ? alt : 'Spanien'}
          />
        </div> : null}

        <Section paddingBottom={60} paddingTop={0} innerStyles={{ maxWidth: 705 }}>


          <h1 className={classes.H1}>
            {title}
          </h1>

          {subtitle ? (
            <p className={classes.subtitle} style={{ fontStyle: 'light italic' }}> {subtitle}</p>
          ) : null}


          {this.props.date ? (
            <div className={classes.date}>
              <span> {date}</span>
            </div>
          ) : null}


        </Section>
      </div>
    )
  }
}

PageHeader.defaultProps = {
  big: false,

}
PageHeader.propTypes = {
  big: PropTypes.bool,

}

export default withStyles(pageHeaderStyle)(PageHeader)
