import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import { Link } from 'gatsby'
import buttonOutlinedGatsbyLinkStyle from './buttonOutlinedGatsbyLinkStyle'
import PropTypes from 'prop-types'
// @material-ui/icons


const ButtonOutlinedGatsbyLink = props => {

  const { classes, inverted } = props

  return (
    <Link
      to={'/' + props.link}
    >
      <div className={classNames(inverted ? classes.rootInverted : classes.root)}>
        {props.title}
      </div>
    </Link>
  )
}
ButtonOutlinedGatsbyLink.defaultProps = {
  link: '/',
  title: '',
  inverted: false,
}
ButtonOutlinedGatsbyLink.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  inverted: PropTypes.bool,
}
export default withStyles(buttonOutlinedGatsbyLinkStyle)(ButtonOutlinedGatsbyLink)
